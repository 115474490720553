/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;600;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap'); */
*{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  position: relative;
  font-size: 16px;
  background: #fff;
  min-height: 1000px;
  /* font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Cabin', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
input, textarea, select, button {
  -webkit-box-sizing: border-box; /* For legacy WebKit based browsers */
     -moz-box-sizing: border-box; /* For legacy (Firefox <29) Gecko based browsers */
          box-sizing: border-box;
          font-family: inherit; 
          font-size: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*Estilo del scroll en chrome*/

::-webkit-scrollbar
{
  width: 4px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track
{
}

::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background: #B9B9B9;
}