.no-arrow-inp::-webkit-inner-spin-button,
.no-arrow-inp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrow-inp {
  -moz-appearance: textfield; /* Firefox */
}
.item-stock{
  border-bottom: 1px solid gainsboro;
}
.bg-pay{
  background: #f1f4ff;
  border: 1px solid #c9c9ff;
}
.nam_stcok_prd{
  font-size: .95em;
}
.rw-input{
  display: block;
  padding: 0.5em 1em !important;
  width: 100%;
  display: block;
  border-radius: 12px;
  color: black;
  border: 1px solid #e6e3e3 !important;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: .95em !important;
  padding-left: 2.5em !important;
}
.rw-input:hover{
  border: 1px solid #2196f3 !important;
}
.rw-status-focus .rw-widget-picker .rw-widget-input{
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1) !important;
}
.cont-ip:hover .float_icn_star{
  color: #2196f3 !important;
}
.rw-list-option:hover, .rw-list-option-create:hover, [data-intent=keyboard] .rw-list-option.rw-state-focus:not(.rw-state-selected), [data-intent=keyboard] .rw-state-focus.rw-list-option-create:not(.rw-state-selected){
  background-color: #dbebfb !important;
  border-color: #e9ecef !important;
  color: #2196f3 !important;
}
.rw-widget-input{
  color: black !important;
  border-radius: 13px !important;
}
.rw-widget-picker{
  border: none !important;
}
.btn_donload_sm {
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px !important;
  border-radius: 50%;
  background: transparent;
  border: 0;
  font-size: 1.4em;
  vertical-align: inherit;
  margin-left: .3em;
  color: gray;
}
.btn_donload_sm:hover {
  color: black;
}
.btn_donload_sm:active {
  background: rgb(235 235 235);
  color: #27af70;
}
.isDown{
  background: #27af70;
  color: white;
}
.accordion-btn{
  position: relative;
  border: 0;
  padding: 0.5em;
  border-radius: 8px;
  background: #e1e1e1;
  border: 1px solid #ebe7e7;
  transition: .2s;
}
.bol-stock{
  display: inline-block;
  width: 18px;
  padding: 0.2em 1em;
  text-align: center;
  background: gainsboro;
  color: #6e6363;
  border-radius: 8px;
  font-size: .8em;
}
.link{
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.accordion-btn:hover{
  background: #c7c7c7;
}
.bol-accr{
  display: inline-flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #484747;
}
.text-accr{
  font-size: .9em;
  text-transform: uppercase;
  color: #29292c;
  font-weight: bold;
}
.inp-number{
  position: relative;
  display: block;
  width: 80px;
  padding: 0.4em;
  font-size: .95em;
  border: 0;
  border: 1px solid gainsboro;
  border-radius: 5px;
}
.tag_inl {
  display: inline-block;
  vertical-align: top;
  margin-top: .2em !important;
}
.stx_tt{
  margin-top:-.3em !important;
}
.is_link{
  text-decoration: none;
  color: #0d6efd!important;
  cursor: pointer;
}
.is_link:hover{
  color: #004ab9 !important;
}
.is_link:active{
  color: #02275f !important;
}
.is_link_purple{
  text-decoration: none;
  color: #c208da !important;
  cursor: pointer;
}
.is_link_purple:hover{
  color: #820692 !important;
}
.is_link_purple:active{
  color: #3f0247 !important;
}
.sz-shar-icn{
  font-size: 1.3em;
}
.fw-500{
  font-weight: 500;
}
.isPay{
  display: inline-block;
  padding: 0.3em 1em;
  background: #089b3c;
  border-radius: 20px;
  color: white;
  font-size: .85em;
}
.n-vert-mg{
  margin: 0.1em auto;
}
.clear-my{
  margin-top: 0;
  margin-bottom: 0;
}
.clear-m-p{
  margin: 0 !important;
  padding: 0 !important;
}
.item-sell{
  position: relative;
  text-decoration: none;
  display: block;
  color: black;
  padding: .6em 1em;
}
.item-sell:hover{
  background: whitesmoke;
}
.dnone{
  display: none !important;
}
.sm-text{
  font-size: .85em;
}
.bg-danger-light{
  border: 1px solid;
  color: #ad2d38;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.bg-primary-full{
  border: 1px solid;
  color: white;
  background-color: rgb(8, 102, 195) !important;
  border-color: #2a4aff;
}
.bg-done-full{
  color: white;
  background-color: #09ad40 !important;
}
.align-icon{
  vertical-align: bottom;
}
.tg-state-gra{
  position: relative;
  padding: .2em 1em;
  border-radius: 20px;
  background: #f7e3e3;
  box-shadow: 0px 0px 1px #e1e1e1;
  color: rgb(220, 53, 69);
  font-size: .9em;
}
.tg-state-lib{
  position: relative;
  padding: .2em 1em;
  border-radius: 20px;
  background: #cfffde;
  box-shadow: 0px 0px 1px #e1e1e1;
  color: #0bb96a;
  font-size: .9em;
}
.tg-state-apa{
  position: relative;
  padding: .2em 1em;
  border-radius: 20px;
  background: #f7e9e3;
  box-shadow: 0px 0px 1px #e1e1e1;
  color: #ff903f;
  font-size: .9em;
}
.tg-state-cre{
  position: relative;
  padding: .2em 1em;
  border-radius: 20px;
  background: #ebebeb;
  box-shadow: 0px 0px 1px #e1e1e1;
  color: rgb(86, 101, 116);
  font-size: .9em;
}
.icn-sate{
  font-size: 1.1em;
  vertical-align: bottom;
  margin-right: .3em;
}
.tag{
  position: relative;
  padding: .2em;
  border-radius: 5px;
}
.tag-done{
  position: relative;
  padding: .2em 1em;
  background: #b4fbc0;
  color: #397944;
  border-radius: 20px;
}
.tag-fail{
  position: relative;
  padding: .2em 1em;
  border-radius: 20px;
  background: #ffdddd;
  color: #e41a1a;
}
.bg-warning-300{
  background: rgb(237, 108, 2);
  color: white;
}
.bg-secondary-200{
  background: #efefef;;
}
.bg-primary-300{
  background-color: rgb(2, 136, 209);
  color: white;
}
.bg-success {
  background-color: #198754!important;
}
.bg-danger {
  background-color: #dc3545!important;
}
.bg-danger-light{
  border: 1px solid;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.is-paying{
  color: #ffffff !important;
  background-color: #198754!important;
}
.no-paying{
  color: #198754 !important;
  background-color: #b8ffb3!important;
}
.fs-sm{
  font-size: .85em;
}
.fs-md{
  font-size: 1.5em;
}
.fs-8{
  font-size: .85em;
}
.fs-9{
  font-size: .95em;
}
.pb-5{
  padding-bottom: 3.5em;
}
.pb-1{
  padding-bottom: .5em;
}
.bg-light{
  background-color: #f8f9fa !important;
}
.color-secondary{
  color: #525252;
}
.rounded-4{
  border-radius: 4px;
}
.rounded-10{
  border-radius: 10px;
}
.h-100{
  min-height: 100px;
}
.h-200{
  min-height: 200px;
}
.d-flex{
  display: flex;
}
.mx-1{
  margin: 0 .5em;
}
.mx-2{
  margin: 0 1em;
}
.mx-3{
  margin: 0 1.5em;
}
.my-1{
  margin: .5em 0;
}
.my-2{
  margin: 1em 0;
}
.my-3{
  margin: 1.5em 0;
}
.mt-p3{
  margin-top: .3em;
}
.mt-1{
  margin-top: .5em;
}
.mt-2{
  margin-top: 1em;
}
.mt-3{
  margin-top: 1.5em;
}
.w-100{
  width: 100%;
}
.w-95{
  width: 95%;
}
.w-90{
  width: 90%;
}
.w-rep{
  width: 70%;
}
.m-w-600{
  width: 600px;
}
.mg-auto-h{
  margin:0 auto;
}
.text-decoration-none{
  text-decoration: none;
}
.border-top{
  border-top: 1px solid gainsboro;
}
.border-bottom{
  border-bottom: 1px solid gainsboro;
}
.f_imp{
  display: flex !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col{
  flex: 1 0 0%;
}
.col-2{
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-3{
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.col-5{
  flex: 0 0 auto;
  width: 41.6666666667%;
}
.col-7{
  flex: 0 0 auto;
  width: 58.3333333333%;
}
.col-8{
  flex: 0 0 auto;
  width: 66.6666666667%;
}
.col-9{
  flex: 0 0 auto;
  width: 75%;
}
.col-10{
  flex: 0 0 auto;
  width: 83.3333333333%;
}
.col-lg-10{
  flex: 0 0 auto;
  width: 83.3333333333%;
}
.gap-1{
  gap:0.5em;
}
.gap-2{
  gap:1em;
}
.gap-3{
  gap:1.5em;
}
.cont-intern-progrss{
  display: block;
  overflow: hidden;
}
.position-realtive{
  position: relative;
}
.bg-red-100{
  background: rgb(250, 210, 210);
}
.bg-success-300{
  background-color: rgb(46, 125, 50);
  color: white;
}
.bg-danger-300{
  background-color: rgb(211, 47, 47) !important;
  color: white;
}
.isActive{
  /* background: #efefef; */
  color: #007bff !important;
}
.isActivep{
  color: #007bff !important;
}
.container_main{
  position: relative;
  margin-top: 4em;
}
.ms-sm-auto{
  margin-left:auto !important;
}
.color-danger-300{
  color: rgb(211, 47, 47) !important;
}
.color-dark-300{
  color: rgb(0, 0, 0) !important;
}
.color-success-300{
  color: rgb(46, 125, 50) !important;
}
.border-top{
  border-top: 1px solid #dee2e6 !important;
}
.border-right{
  border-right: 1px solid #dee2e6 !important;
}
.border-radius{
  border-radius: 50%;
}
.outline-none:focus{
  outline: none !important;
  box-shadow: none !important;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-items-center{
  align-items: center;
}
.py-0{
  padding: 0 0 !important;
}
.py-1{
  padding: .5em 0;
}
.py-2{
  padding: 1em 0;
}
.py-3{
  padding: 1.5em 0;
}
.p-menu-mov{
  padding: .5em;
}
.p-1{
  padding: .5em;
}
.p-2{
  padding: 1em;
}
.p-3{
  padding: 1.5em;
}
.pr-1{
  padding-right: .5em;
}
.pr-2{
  padding-right: 1em;
}
.pr-3{
  padding-right: 1.5em;
}
.pr-4{
  padding-right: 2em;
}
.pr-6{
  padding-right: 3em;
}
.fs-90{
  font-size: .9em;
}
.fs-85{
  font-size: .85em;
}
.fs-15{
  font-size: 1.5em;
}
.fs-75{
  font-size: .75em;
}
.t-upper-c{
  text-transform: uppercase;
}
.icon-opc{
  font-size: 1.8em;
  vertical-align: middle;
  color: #504b4b;
}
.wrapper-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.wrapper-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.wrapper-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.wrapper-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: .5em;
  grid-column-gap: .5em;
}
.wrapper-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: .2em;
  grid-column-gap: .2em;
}
.card-desin-t{
  position: relative;
  display: display;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  cursor: pointer;
  transition: .2s;
}
.card-desin-t:hover{
  box-shadow: 0px 2px 3px gainsboro;
}
.icon-tablon{
  padding: .5em;
  display: inline-block;
  width: 23px;
  height: 23px;
  background: #007bff;
  color: white;
  border-radius: 50%;
}
.icon-tablon-img{
  display: inline-block;
  background: #f1f1f1;
  padding: .1em;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.title-crd-t{
  font-size: 1.1em;
  font-weight: lighter;
}
.show-sm{
  display: none !important;
}
.text-uppercase{
  text-transform: uppercase !important;
}
.text-lowercase{
  text-transform: lowercase !important;
}
.flex-column{
  flex-direction: column !important;
}
.content-wd-ed{
  margin: 1em auto;
  max-width: 850px;
}
.inp-num{
  text-align: center;
  font-size: 1em;
  padding: 0.2em 0;
  font-size: 3em;
  font-weight: 800;
}
.fixed-upload {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1100;
  /* background: rgb(255 255 255 / 95%); */
  background: white;
  border-radius: 10px;
}
.center-content{
  position: absolute;
  width: 400px;
  height: 200px;
  left: 0;
  right: 0;
  top: -10em;
  bottom: 0;
  margin: auto;
}
.icon-upld-anim{
  position: relative;
  width: 50px;
  height: 50px;
  color: #007bff;
  -webkit-animation: slideInUpOpc 1.5s infinite;
  animation: slideInUpOpc 1.5s infinite;
}
.fixed-top-barr{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  z-index: 1100;
}
.position-relative{
  position: relative !important;
}
.position-sticky{
  position: sticky !important;
}
.h-550{
  min-height: 550px;
}
.end_aling{
  position: absolute;
  right: 2em;
}
.loadInit{
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  margin: auto;
}
.badge{
  display: inline-block;
  padding: 0.35em 0.65em;
  font-weight: 500;
  font-size: .85em;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pt-1{
  padding-top: .5em;
}
.pt-3{
  padding-top: 1.5em;
}
.px-2{
  padding: 0 1em;
}
.px-3{
  padding: 0 1.5em;
}
.mb-3{
  margin-bottom: 1.5em !important;
}
.mb-2{
  margin-bottom: 1em !important;
}
.mb-1{
  margin-bottom: .5em !important;
}
.mb-6{
  margin-bottom: 2em !important;
}
.text-center{
  text-align: center !important;
}
.text-right{
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}
.text-secondary{
  color: gray;
}
.text-danger{
  color: rgb(247, 35, 35);
}
.d-block{
  display: block;
}
.bd-gain-1{
  border-bottom: 1px solid gainsboro;
}
.cont_ocl_ft{
  position: relative;
  display: block;
  max-height: 200px;
  overflow: hidden;
}
.item-branch:hover{
  outline-style: solid;
  outline-width: 2px;
  outline-offset: 1px;
  outline-color: #5c5c5c;
  border-color: gainsboro;
  /* box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1); */
}
.bg-grblue{
  background: rgb(160 213 209 / 20%);
  padding: 0.4em 1em;
  border-radius: 8px;
}
.color-primary{
  color: #007bff;
}
.icn-arrow{
  vertical-align: top;
  margin-top: 0.17em;
  width: 18px;
  height: 18px;
}
.sm-text-75{
  font-size: .75em;
}
.vt-icn{
  vertical-align: middle;
  font-size: .9em;
}
.vt-bott{
  vertical-align: bottom;
}
.img_full{
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.icon_in_br{
  color: rgb(158, 158, 158);
  margin-right: .3em;
}
.text-br{
  vertical-align: super;
  padding: 0 .5em;
  color: rgb(77, 77, 77);
}
.dg-br{
  vertical-align: super;
  padding: 0 1em;
  color: gainsboro;
}
.nav{
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.sub-link{
  display: block;
  padding: 0.5rem 1.3rem;
  color: #767676;
  font-weight: 500;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  cursor: pointer;
}
.sub-link:hover{
  color: #0049b5;
}
.sub-link:active{
  color: #3b89ff;
}
.nav-link{
  display: block;
  padding: 0.7rem 1.5rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  cursor: pointer;
}
.fixed-right-menu .nav-link{
  font-weight: 500;
  color: #656565;
}
.fixed-right-menu .nav-link:hover{
  color: #007bff !important;
}
.fixed-right-menu .nav-link:active{
  color: #007bff !important;
  font-weight: bold;
}
.nav-link svg{
  width: 21px;
  height: 21px;
  vertical-align: text-bottom;
  margin-right: 10px;
  /* color: #9b9b9b; */
}
.title-med{
  font-size: 1.6em;
}
.cont-icons-head{
  position: absolute;
  right: 14em;
  top: 0;
}
.btn-head {
  border: 0;
  background: transparent;
  padding: 0.78em 1.5em;
  color: #555758;
}
.btn-head:hover {
  background: #303336;
  color: #e5e5e5;
  cursor: pointer;
}
.cl-t-he {
  display: block;
}
.icon-head {
  font-size: 1.2em;
}
.sel{
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.sl-button{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: .875rem;
}
.btn-outline-green{
  background-color: white;
  border: 1px solid #198754 !important;
  color: #198754;
}
.btn-outline-green:hover{
  background-color: #198754;
  color: white;
}
.btn-green:focus, .btn-outline-green:focus{
  box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 50%);
}
.btn-icon{
  position: relative;
  border: 0;
  line-height: 2.7;
  padding: 0 1em;
  padding-right: 1.5em;
  border-radius: 5px;
  text-transform: uppercase;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.min-w-btn{
  min-width: 160px;
}
.btn{
  position: relative;
  border: 0;
  line-height: 2.7;
  padding: 0 2em;
  border-radius: 5px;
  /* text-transform: uppercase; */
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-size: 16px;
}
.btn_play{
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
  width: 45px;
  height: 45px;
  line-height: 47px !important;
  background: white;
  border-radius: 50% !important;
}
.btn_play:hover{
  background: #b0d3f5;
}
.btn_reload{
  display: block;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
  width: 23px;
  height: 23px;
  padding: .3em !important;
  line-height: 47px !important;
  background: white;
  border-radius: 50% !important;
}
.btn_reload:hover{
  background: #b0d3f5;
}
.is_grab{
  color: #dc3545;
}
.is_libr{
  color: #0bb96a;
}
.is_apar{
  color: #ff903f;
}
.icon-ply{
  font-size: 1.8em;
}
.is_shar{
  color: #007bff;
}
.fs-9{
  font-size: .9em;
}
.btn-sm{
  padding: 0.5em .8em !important;
  font-size: .85em !important;
  line-height: normal;
}
.btn-sm-icon{
  padding: 0.3em 0.5em !important;
  line-height: .5em;
}
.icn_trsh{
  width: 17px !important;
  height: 17px !important;
}
.icn-del{
  width: 13px !important;
  height: 13px !important;
}
.btn-ft-sm{
  font-size: .9em !important;
}
.btn-primary:focus, .btn-outline-primary:focus{
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}
.btn-danger:focus, .btn-outline-danger:focus{
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 50%);
}
.btn-warning:focus, .btn-outline-warning:focus{
  box-shadow: 0 0 0 0.25rem rgb(255 193 7 / 50%);
}
.btn:disabled{
  pointer-events: none;
  opacity: .65;
}
.btn-warning{
  background: rgb(255 193 7);
  color: #744d02;
}
.btn-danger{
  color: white;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:focus{
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgb(225 83 97 / 50%);
}
.btn-outline-primary{
  background-color: white;
  border: 1px solid rgb(25, 118, 210);
  color: rgb(25, 118, 210);
}
.btn-outline-primary:hover{
  background: rgb(25, 118, 210);
  color: white;
}
.btn-outline-danger{
  background-color: white;
  border: 1px solid #be4e4a;
  color: #be4e4a;
}
.btn-outline-danger:hover{
  background: #be4e4a;
  color: white;
}
.btn-primary{
  background: rgb(25, 118, 210);
  color: white;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}
.btn-upload{
  color: white;
  background-color: rgb(132 79 225);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}
.btn-upload:active{
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
}
.btn-primary:hover{
  background-color: rgb(21, 101, 192);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}
.btn-warning:hover{
  background-color: rgb(255 193 7);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}
.btn-primary:active{
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
}
.btn-outline-transparent{
  background: transparent;
}
.btn-toolbar{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.bott-float{
  position: absolute;
  bottom: 0;
}
.icon-btn{
  font-size: 1.5em;
}
.align-items-center{
  align-items: center!important;
}
.border{
  border: 1px solid #dee2e6;
}
.border-bottom{
  border-bottom: 1px solid #dee2e6!important;
}
.border-text-black-50{
  border: 1px solid #dee2e6 !important;
}
.rounded{
  border-radius: 0.25rem!important;
}
.file-in-button{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.content-croppie{
  width: 100%;
  height: 250px;
  border-radius: 5px;
}
.content-central{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 1000px;
  height: 550px;
  margin:auto;
  background: white;
  border-radius: 10px;
  overflow: hidden;
}
.bg-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.crop-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
}
.controls{
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 63%;
  transform: translateX(-50%);
  height: 20px;
  display: flex;
  align-items: center;
}
.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 3px solid #e1e1e1;
  border-bottom: 3px solid #dfdfdf;
  background: #3f51b5;
  width: 100%;
  border-radius: 1px;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
.text-sm-secondary{
  margin: 0;
  padding: 0;
  color: #afafaf;
  font-weight: lighter;
}
.mb-0{
  margin-bottom: 0;
}
.tt-ficha{
  font-size: 1.2em;
  padding: 0.2em 0.5em;
  background: #198754;
  border-radius: 16px;
  color: white;
  padding-left: 1em;
  text-transform: uppercase;
}
.sub-tt-ficha{
  padding: 0.3em 0.5em;
  font-weight: 500;
  font-size: .95em;
  background: #198754;
  color: white;
  border-radius: 16px;
  padding-left: 1em;
}
.item-ticket{
  font-size: .9em;
}
.list-ficha{
  font-size: .9em;
  color: #5a5757;
  margin: 0.5em;
  list-style: none;
}
.list-ticket{
  margin: 0 auto;
  list-style: none;
  color: gray;
  text-align: center;
}
.w-120{
  width: 100px;
}
.tt-ticket{
  display: block;
  position: relative;
  padding: .2em .8em;
  background: #212529;
  border-radius: 20px;
  color: white;
  font-size: 1.2em;
}
.img-ticket{
  width: 100px;
  display: inline-block;
}
.img-ficha{
  width: 120px;
  display: inline-block;
}
.icon-sm{
  vertical-align: sub;
  font-size: 1.2em;
}
.icon-sm-c{
  font-size: 1.5em;
  vertical-align: bottom;
}
.icon-med{
  width: 29px;
  height: 29px;
}
.tag-price-dash{
  position: relative;
  font-size: 1.3em;
  font-weight: lighter;
}
.tag-price-dash-fw{
  position: relative;
  font-size: 1.5em;
  font-weight: bold;
}
.util-head-cont{
  position: relative;
  display: inline-block;
  margin-left: 1em;
  top: 0.1em;
}
.abs-end{
  position: absolute !important;
  top: -0.18em;
  margin-left: 0.3em;
}
.end-edit{
  position: absolute !important;
  top: 0.15em;
  margin-left: 0.3em;
  right: 1em;
}
.text-uppercase{
  text-transform: uppercase;
}
.lb-filters{
  position: relative;
  display: block;
  color: #917d59;
  font-size: .95em;
  padding: 0.5em 0;
}
.lb-control{
  color: #917d59;
  font-size: .85em;
  padding: 0.3em 0;
}
.icon-usm{
  width: 18px;
  height: 18px;
}
.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 62px;
  background-color: #212529!important;
  padding: 0.1em;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  z-index: 1000;
}
.cont_logo {
  display: inline-block;
  width: 16.55%;
  vertical-align: top;
  border-right: 1px solid #424242;
  padding: 0.5em 0;
}
.fs-hm{
  width: 25px !important;
  height: 25px !important;
}
.fs-hm-m{
  font-size: 1.7em !important;
}
.logo_empresa{
  width: 155px;
  /* height: 45px; */
  margin-left: 1.1em;
}
.logo_empresa_mov{
  width: 50px;
  height: 50px;
  margin-left: 1.1em;
}
.cont-study{
  display: inline-block;
  padding: .45em 1em;
  background: #14171a;
}
.tp_study{
  display: block;
  font-size: .8em;
  font-weight: normal;
  color: #48a0fd;
  margin: 0 auto;
}
.tp_study_nm{
  display: block;
  font-size: .7em;
  font-weight: normal;
  color: #797b7d;
  margin: 0 auto;
}
.img_study{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: .4em;
}
.cnt_lg_s{
  display: inline-block;
  vertical-align: top;
}
.sub_letter{
  position: relative;
  display: block;
  font-size: .75em;
  color: #009688;
  margin-top: -0.2em;
  font-weight: lighter;
}
.tiutl_insti{
  display: block;
  font-weight: bold;
  font-size: 1.4em;
  color: white;
  margin: 0;
  margin-left: .4em;
  padding: 0 .2em;
}
.tiutl_insti:hover{
  text-decoration: underline;
}
.icon_it_he{
  display: inline-block;
  font-size: 1.4em;
  vertical-align: sub;
  margin-right: 0.5em;
}
.icon_it_mv{
  display: block;
  font-size: 1.6em;
  width: 100%;
}
.tex_hed_m{
  display: block;
  font-size: .6em;
  text-transform: uppercase;
  width: 100%;
  margin-top: .4em;
}
.user_opc_cont{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  text-align: right;
  padding: 0.1em 0;
}
.user_opc{
  display: inline-block;
  vertical-align: top;
  padding: .3em 1em;
  cursor: pointer;
}
.user_opc:hover{
  background: #403d3d;
}
.list_p{
  display: inline-block;
  margin-right: .3em;
  margin-top: .2em;
  cursor: pointer;
}
.icon-menu{
  visibility: hidden;
  position: absolute !important;
  right: 1em;
  width: 36px;
  height: 36px;
  color: gainsboro;
  top: 1em;
  /* padding: 0.1em; */
  text-align: center;
  border-radius: 50%;
}
.t_upperCase{
  text-transform: uppercase;
}
.tex_hed_i {
  vertical-align: inherit;
  margin-left: 0.3em;
}
.icn_inc_m{
  width: 35px;
  height: 35px;
  line-height: 35px !important;
}
.nombre_user{
  display: inline-block;
  color: #416af9;
  padding: .5em;
  vertical-align: top;
  margin-top: .1em;
  margin-right: .2em;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}
.img_user{
  margin-top: .5em;
  width: 32px;
  border-radius: 50%;
  box-shadow: 0px 2px 3px #000000;
}
.icon_Sa{
  display: inline-block;
  cursor: pointer;
  width: 13px;
  height: 24px;
  line-height: -1px !important;
  text-align: center;
  border-radius: 50%;
  margin-top: 0.8em;
  color: #757575;
  padding: 0.3em;
  vertical-align: top;
  margin-right: 0.5em;
}
.icon_friends{
  display: inline-block;
  cursor: pointer;
  font-size: 1.6em;
  line-height: -1px !important;
  text-align: center;
  border-radius: 50%;
  color: #757575;
  padding: 0.5em;
  border-radius: 50%;
  margin-top: .25em;
  transition: .3s;
  cursor: pointer;
}
.icon_friends:hover {
  background: #373b40;
}
.icon_friends:active{
  background: #14171a;
}
.tag-number{
  position: absolute;
  display: inline-block;
  top: .8em;
  left: 2.5em;
  width: 18px;
  height: 18px;
  font-size: .75em;
  line-height: 18px !important;
  text-align: center;
  background: #e73647;
  color: white;
  border-radius: 50%;
  text-decoration: none;
}
.tp_almn{
  display: block;
  font-size: .8em;
  text-align: right;
  font-weight: normal;
  color: #a5a198;
  margin: 0 auto;
}
.contenedor_main{
  position: relative;
  display: block;
  max-width: 1200px;
  background: white;
  box-shadow: 0px 3px 5px gainsboro;
  border:1px solid gainsboro;
  margin: 0 auto;
  margin-top: 5em;
}
.m-h-500{
  min-height: 500px;
}
.menu_salir {
  position: fixed;
  right: 1%;
  width: 220px;
  top: 4.2em;
  padding: 0.5em;
  background: white;
  border: 1px solid #d4d3d3;
  border-radius: 5px;
  overflow: hidden;
  z-index: 200;
}
.list_opc {
  display: block;
  list-style: none;
  text-align: right;
}
.item_opc_salir {
  display: block !important;
  padding: .4em;
  text-decoration: none;
  color: #464141;
  cursor: pointer;
}
.item_opc_salir:hover{
  background: whitesmoke;
}
.link_text {
  display: inline-block;
  padding: .5em 0;
  padding-right: 1em;
  font-size: .9em;
}
.icon_list {
  display: inline-block;
  padding: 0.3em;
  padding-left: .8em;
  border-left: 1px solid #f0f0f0;
  font-size: 1.2em;
  vertical-align: bottom;
}
.cont_downloaad{
  position: relative;
  text-decoration: none;
  background-color: white;
  padding: 0.8em 1.5em;
  border: 1px solid gainsboro;
  border-radius: 35px;
  cursor: pointer;
}
.ext_down{
  position: absolute;
  font-size: 1.2em;
  color: #b5b5b5;
  font-weight: lighter;
  top: 1em;
  right: 1.3em;
}
.tag_tt_song{
  font-weight: bold;
  font-size: 1em;
}
.landing_ani{
  background: url('https://songor.mx/images/fondo_1.jpeg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  /* background: gainsboro; */
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  /* background: #659999; */
  /* background: -webkit-linear-gradient(to right, #659999, #f4791f); */
  /* background: linear-gradient(to right, rgb(101 153 153 / 30%), rgb(244 121 31 / 20%)); */
  z-index: 10;
}
.opacy_login{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgb(0 0 0 / 10%);
}
.cont_login{
  position: relative;
  max-width: 500px;
  height: auto;
  padding: 2em 1em;
  z-index: 200;
  margin: 0 auto;
  margin-top: 3em;
}
.cont_signup{
  position: relative;
  max-width: 500px;
  height: auto;
  padding: 2em 1em;
  z-index: 200;
  margin: 0 auto;
  margin-top: 1em;
}
.cont_img {
  position: relative;
  padding: 0.5em;
  text-align: center;
}
.m-w-300{
  max-width: 300px;
  margin: 0 auto;
}
.mr-tp-icn{
  top: .65em !important;
}
.sm-tt-forgot{
  font-size: 1.2em;
}
.img_tit_log{
  display: inline-block;
  vertical-align: bottom;
  width: 130px;
  vertical-align: middle;
}
.img_sing_log{
  display: inline-block;
  vertical-align: bottom;
  width: 80px;
  vertical-align: middle;
}
.titul_login {
  text-align: center;
  padding: .5em 1em;
  margin: 0 auto;
  vertical-align: sub;
  font-size: 2em;
  color: black;
  font-weight: 800;
}
.form_login{
  transition: all .5s linear;
}
.icon-inp{
  position: absolute;
  left: 1em;
  top: 0.5em;
  width: 25px;
  height: 28px;
  color: gray;
}
.show-pass{
  position: absolute;
  right: 1em;
  top: .7em;
  width: 22px;
  height: 28px;
  color: gray;
  transition: .1s;
}
.show-pass:hover{
  transform: scale(.93);
  cursor: pointer;
  color: #2f3450;
}
.blue-focus{
  color: #3f51b5 !important;
}
.img-inp{
  position: absolute;
  left: .8em;
  top: 0.7em;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.cnt_inp {
  position: relative;
  max-width: 70%;
  margin: .8em auto;
}
.cion_inp_log{
  position: absolute;
  top: .6em;
  left: .6em;
  color: #949494;
  border-radius: 50%;
  font-size: 1.5em;
}
.clear_sl_sm{
  padding: 0.42em 1em !important;
}
.inp-sm{
  padding: 0.5em 1em !important;
}
.inplb{
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  background: lightgrey;
  border-radius: 4px 0px 0px 4px;
  width: 30px;
  line-height: 3em;
  text-align: center;
}
.pd-lf-inp{
  padding-left: 3em !important;
}
.inp_login {
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 2;
  line-height: 1.5rem;
  border-width: 1px;
  border-color: hsl(var(--bc)/var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1)/var(--tw-bg-opacity));
  border-radius: var(--rounded-btn,.5rem);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  background: white;
  padding-left: 3.5em;
  color: #000000e0;
}
.inp_login:focus {
  background-color: #fff;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(140 218 255 / 25%);
}
.icon_view {
  position: absolute !important;
  right: .8em;
  top: .8em;
  color: #bfbfbf;
  cursor: pointer;
  padding: .3em;
  border-radius: 50%;
}
.icon_view:hover{
  color: #23c1e4;
}
.border-form {
  border: 1px solid gainsboro;
  border-radius: 10px;
  background: white;
}
.forgot {
  display: block;
  max-width: 300px;
  margin: 0 auto;
  text-align: right;
  color: #2196f3;
  padding: .5em;
  text-decoration: none;
  text-align: center;
}
.btn_login{
  display: block;
  background: rgb(18 97 219);
  height: 48px;
  box-shadow: 2px 2px 5px rgb(66 19 30 / 60%);
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 1.1em;
  text-transform: uppercase;
}
.btn_login:hover{
  background: #2682dd;
}
.btn_login:disabled{
  opacity: .65;
  pointer-events: none;
}
.btn_login:disabled{
  opacity: .65;
  pointer-events: none;
}
.text-filter{
  color: #416af9;
  background: #f0f2f7;
  padding: .5em;
  border-radius: 5px;
  font-size: .95em;
}
.area_disa {
  pointer-events: initial;
}
.main_option_center{
  position: absolute;
  top: -20em;
  bottom: 0;
  left: 0;
  right: 0;
  width: 250px;
  height: 320px;
  background: white;
  border-top: 1px solid #c1bebe;
  margin: auto;
  box-shadow: 3px 0px 20px #bdbdbd;
}
.cont_option{
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgb(43 43 43 / 20%);
  z-index: 1000;
}
.main_option{
  position: absolute;
  bottom: 0;
  left: 2em;
  right: 2em;
  min-height: 200px;
  background: white;
  border-top: 1px solid #c1bebe;
  margin: 0 auto;
  box-shadow: 3px 0px 20px #bdbdbd;
}
.cont_modal{
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgb(43 43 43 / 50%);
  z-index: 1000;
  overflow-y: scroll;
}
.main_modal {
  position: relative;
  display: block;
  max-width: 800px;
  min-height: 500px;
  background: #f9f9f9;
  border: 1px solid #6c6c6c;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #3e3e3e;
}
.v-hidden{
  visibility: hidden;
}
.sel-type{
  padding: 0.5em 1em;
  border: 0;
  border: 1px solid gainsboro;
  border-radius: 5px;
}
.hg-auto{
  min-height: auto;
}
.modal-md{
  max-width: 800px !important;
}
.modal-lg{
  max-width: 1200px !important;
}
.modal-sm{
  max-width: 600px !important;
}
.modal-u-sm{
  max-width: 400px !important;
}
.cerrar_modal{
  position: absolute;
  color: #bfbfbf;
  top: 1em;
  right: 1em;
  z-index: 100;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 40px !important;
  text-align: center;
}
.cerrar_modal:hover{
  color: black;
}
.ultr-sm{
  color: #000;
  font-size: .85em;
}
.btn_track{
  position: relative;
  background: transparent;
  border: 0;
  color: #b7b7b7;
  font-size: 1em;
  width: 50px;
  height: 50px;
  padding-top: .22em;
  border-radius: 50%;
}
.btn_closed_track{
  position: absolute !important;
  right: 1em;
  top: .6em;
  background: #4d4d4d;
}
.btn_track:hover {
  background:#2e3236;
}
.btn_track:active {
  color:#212529;
}
.btn_back{
  display: none !important;
  position: relative;
  background: transparent;
  border: 0;
  color: #1976d2;
  font-size: 1.3em;
  width: 43px;
  height: 43px;
  padding-top: .22em;
  border-radius: 50%;
}
.btn_back:active {
  color:#5dafff;
}
.head_modal{
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  padding: .8em 5em;
  border-bottom: 1px solid #dee2e6;
  background: white;
  border-radius: 10px 10px 0 0;
}
.modal-tittle{
  font-size: 1.35em;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
}
.modal_body{
  position: relative;
  flex: 1 1 auto;
  padding: 3em 5em;
}
.clear_sl_p{
  padding: 0.60em 1em !important;
}
.pd-l-2{
  padding-left: 2em !important;
}
.lb-inp-usm{
  display: block;
  font-size: .8em;
  text-transform: uppercase;
  font-weight: bold;
}
.sl_tranp{
  display: block;
  border: 0;
  text-align: center;
  width: 100%;
  padding: 0.3em 0;
  border: 1px solid gainsboro;
  border-radius: 8px;
}
.inp_tranp{
  display: block;
  width: 100%;
  border: 0;
  text-align: center;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 0.6em 0;
  outline: none;
}
.css-13cymwt-control{
  display: block;
  padding: 0.13em .5em;
  width: 100%;
  display: block;
  border-radius: 12px !important;
  border: 0;
  /* box-shadow: 1px 1px 1px #e6e6e6; */
  border: 1px solid #e6e3e3;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-min-logical-width: calc(100% - 16px);
  line-height: 20px !important;
  background:white;
  border-color: #e6e3e3 !important;
}
.css-t3ipsp-control{
  padding: 0.13em .2em;
  border-radius: 15px !important;
  background-color: #fff;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(140 218 255 / 25%) !important;
}
.inp_add{
  display: block;
  padding: 0.58em 1em;
  width: 100%;
  display: block;
  border-radius: 12px;
  border: 0;
  /* box-shadow: 1px 1px 1px #e6e6e6; */
  border: 1px solid #e6e3e3;
  outline: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-min-logical-width: calc(100% - 16px);
  line-height: 20px !important;
  background:white;
}
.in-requi{
  border: 1px solid #2196f3;
}
.tg-requir{
  color: #416af9;
}
.date-mv{
  font-size: .75em;
  color: gray;
}
.icn_fl_sel{
  position: absolute;
  top: 0.6em;
  right: .8em;
  color: #007bff;
}
.inp-sm-nob{
  padding: 0.45em 1em !important;
  padding-left: 2em !important;
}
.inp_add:focus {
  background-color: #fff;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(140 218 255 / 25%);
}
.img_inl{
  display: inline-block;
}
.text_inl{
  display: inline-block;
  margin-left: .5em;
}
.label_regis{
  display: block;
  padding: .3em 0;
}
.lb-pers-frm{
  font-size: .85em;
  color: #484646;
  padding: 0.3em 0;
  display: block;
}
.modal_footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  border-radius: 0 0 8px 8px;
  background: white;
  border-top: 1px solid #e1e1e1;
}
.max-w-600{
 max-width: 600px !important;
 margin: 0 auto;
}
.img_qr{
  width: 100px;
}
.w-250{
  max-width: 250px;
  margin: 0 auto;
}
.fw-bold {
  font-weight: 700!important;
}
.fs-7 {
  font-size: .85em;
}
.min-h-350{
  min-height: 250px;
}
.itm-obs{
  padding: 0.4em 0;
  background: white;
  border-radius: 15px;
  border: 1px solid gainsboro;
  padding-left: 0.5em;
}
.table_ser{
  border-collapse: collapse;
  width: 70%;
  margin: 0 auto;
}
.table_ficha_ser{
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
}
.table_ser{
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  text-align: left;
  color: black;
}
.table_ser td, .table_ser th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}
.table_outline{
  border-collapse: collapse;
  width: 100%;
}
.table_outline th {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  text-align: left;
  background-color: #ffffff;
  color: black;
  text-transform: uppercase;
}
.th-border{
  border-bottom: 2px solid #131212 !important;
}
.table_outline td {
  border-bottom: 1px solid #ddd;
  padding: 4px;
}
.table_outline th {
  border-bottom: 1px solid #ddd;
  padding: 8px 4px;
}
.table_outline tr:nth-child(even){background-color: #f2f2f2;}
.table_outline tr:hover {background-color: #ddecff;}
.table{
  border-collapse: collapse;
  width: 100%;
}
.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #fafafa;
  color: black;
  padding: 1em;
  font-weight: 500;
}
.table th {
  border-bottom: 1px solid #ddd;
  padding: 1em;
}
.table td{
  border-bottom: 1px solid #ddd;
  padding: 0.6em 1.2em;
  font-size: .9em;
}
.table th:first-child{
  border-start-start-radius: 10px;
}
.table th:last-child{
  border-start-end-radius: 10px;
}
.table tr:nth-child(even){background-color: #f2f2f2;}
.table tr:hover {background-color: #ddd;}
.barr_gains{
  position: relative;
  display: block;
  height: 3px;
  background: gainsboro;
  z-index: 1600;
}
.isDownloaded{
  position: absolute;
  top: 1.3em;
  right: 1.5em;
}
.isDownloafail{
  position: absolute;
  top: 1em;
  right: 1.5em;
}
.checkisdone{
  width: 11px;
  height: 11px;
  padding: .2em;
  background: #0ec759;
  border-radius: 50%;
  color: #ffffff;
  font-size: 1.5em;
}
.checkisdone{
  width: 11px;
  height: 11px;
  padding: .2em;
  background: #0ec759;
  border-radius: 50%;
  color: #ffffff;
  font-size: 1.5em;
}
.checkfail{
  width: 24px;
  height: 24px;
  padding: .1em;
  background: #ffffff;
  border-radius: 50%;
  color: #ff3a3a;
  font-size: 1.5em;
}
.opc-5{
  opacity: .5;
}
.opc-5 .icon-phone-tg{
  color: gray;
}
.line_progrs_int{
  position: absolute;
  height: 3px;
  background: #007bff;
  border-radius: 10px;
  -webkit-transition: width .3s ease-in-out;
  -moz-transition: width .3s ease-in-out;
  -o-transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
}
.brr_grren{
  background: #0ec759 !important;
}
.brr_red{
  background:#ff4b5c !important;
}
.barr_gray{
  position: relative;
  display: block;
  height: 4px;
  background: #f2f2f2;
  z-index: 1600;
}
.line_progress{
  position: absolute;
  width: 10%;
  height: 3px;
  background: #9b50eb;
  border-radius: 10px;
  animation: carr_prelod 1.3s linear infinite;
}
.aqua-mark{
  position: absolute;
  width: 280px;
  height: 280px;
  left: 0;
  right: 0;
  bottom: 8em;
  margin: 0 auto;
  opacity: .1;
  z-index: 100;
}
.card-white{
  position: relative;
  width: 100% !important;
  font-size: .85em;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 3px gainsboro;
  border: 1px solid #f1f1f1;
}
.card-border{
  position: relative;
  width: 100% !important;
  font-size: .85em;
  border-radius: 8px;
  background: white;
  border: 1px solid #d1d1d1;
}
.tt_ficha_sg{
  background: #004ab9;
  color:white;
  padding: .5em 1em;
  border-radius: 20px;
}
.bg_grab{
  background: #326ad1 !important;
}
.w-100{
  width:100%;
}
.w-85-f{
  width:85%;
  margin:0 auto;
}
.w-70{
  width:70%;
  margin:0 auto;
}
.icon-message{
  width: 2em;
  height: 2em;
  display: inline-block;
  fill: currentColor;
  font-size: inherit;
}
.reload-button{
  width: 2em;
  height: 2em;
  display: inline-block;
  fill: currentColor;
  font-size: inherit;
  color: #1976d2;
  border-radius: 50%;
  transition: .2s;
}
.reload-button:active{
  background: white;
  color: #0049b5;
}
.closed-button{
  width: 2em;
  height: 2em;
  display: inline-block;
  fill: currentColor;
  font-size: inherit;
}
.fixed-left-bottom{
  position: fixed;
  left: 4em;
  bottom: 4em;
  border-radius: 4px;
  overflow: hidden;
  background:white;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
  z-index: 3000;
}
.fixed-play-track{
  /* display: none; */
  position: fixed;
  bottom: 1em;
  left: 17%;
  right: 1em;
  z-index: 0;
  box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  border-radius: 30px !important;
  background: #212529;
}
.fixed-login-content{
  /* display: none; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: #ffffff;
  z-index: 1000;
}
.fixed-right-menu{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}
.fixed-bottom-menu{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  /* box-shadow: inset 1px 1px 0 rgb(175 164 164 / 10%); */
  border-top: 1px solid #dddddd;
}
.nav-link-m{
  display: inline-block;
  padding: .7rem .5em;
  color: #818181;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
}
.list{
  padding: 0;
}
.item-tab{
  position: relative;
  list-style: none;
}
.link-tab{
  position: relative;
  display: inline-block;
  min-width: 60px;
  padding: .8em 2em;
  border-radius: 8px;
  font-size: .85em;
  text-transform: uppercase;
  text-decoration: none;
  color: gray;
  background: white;
  box-shadow: 0px 2px 3px gainsboro;
  border: 0;
}
.act-tab{
  background: white;
  color: #16528d;
  font-weight: bold;
}
/* Estilo para la animación */
.hovered-row {
  background-color: #f0f0f0;
  opacity: 0.8;
  font-size: 16px; /* Ajusta el tamaño del texto si es necesario */
  transform: scale(1.05); /* Agranda un poco el row mientras se arrastra */
  border: 2px dashed #ccc;
}

.dragging-row {
  background-color: lightgray; /* Agrega un fondo diferente mientras se arrastra */
  opacity: 0.5;
}

/* Estilo para transiciones suaves entre rows */
tr {
  transition: transform 0.3s ease; /* Suaviza la transición al moverse */
}
.icon_move{
  font-size: 1.9em;
  display: inline-block;
  color: gray;
  padding: .3em 0em;
}
.icon-md{
  font-size: 1.5em;
}

.icon-md-18{
  font-size: 1.8em;
}
.icon-lg{
  font-size: 2.5em;
}
.paly_act{
  color: #62666b;
}
.opc-2{
  opacity: .2;
}
.item-list{
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  color: #1976d2;
  display: flex !important;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  padding: 15px 2em;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid #ebebeb;
}
.item-list:hover{
  background: gainsboro;
  color: #3850d5;
}
.cont-icon{
  min-width: 56px;
  flex-shrink: 0;
  display: inline-flex;
}
.icon_item{
  font-size: 2em;
}
.tex_item{
  flex: 1 1 auto;
  min-width: 0px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.custom-list_combox{
  position: absolute;
  background: white;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  list-style: none;
}
.item-custom-combo{
  display: block !important;
  list-style: none;
  padding: 0.8em 2em;
  border-bottom: 1px solid gainsboro;
}
[data-reach-combobox-popover] {
    font-size: 1em !important;
    z-index: 1500;
}
[data-reach-combobox-option] {
  padding: .5em 1em !important;
  font-weight: bold !important;
  color: #2196f3;
}
[data-suggested-value]{
  color: black;
  font-weight: normal !important;
}
.cont-hd-right{
  position: relative;
  display: inline-block;
  margin-right: .5em;
  border-right: 1px solid #403d3d;
  margin-top: .5em;
}
.cont-btns-hd{
  position: relative;
  display: inline-block;
  margin-right: .5em;
  vertical-align: top;
}
.sl-trans{
  position: relative;
  background: transparent;
  border: 0;
  outline: none;
  color: #b1b0b0;
}
.is_con_sel{
  border-radius: 8px;
  background: #14171a;
}
.is_con_sel:hover{
  background: #414040;
}
.text-message-srt{
  font-size: .85em;
  color: #f80d0d;
  background: #fbfbfb;
  padding: .5em 1em;
  border-radius: 20px;
}
.btn-link-lg{
  position: relative;
  border: 0;
  background: transparent;
  color: #0d6efd;
  text-decoration: none;
}
.btn-link-lg:hover{
  color: #2c81ff;
}
.btn-link-lg:active{
  color: #02275f;
}
.stepItem{
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 25px;
  background: #e1e1e1;
  color: rgb(159 159 159);
  border-radius: 50%;
  text-align: center;
  margin-right: .3em;
  font-size: .85em;
}
.step-check{
  background: #007bff;
  color: white;
}






.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
.cursor-p{
  cursor: pointer;
}
.tag-inline{
  text-decoration: none;
  display: inline-block;
  padding: 0.3em 0;
}
.tag-bread{
  text-decoration: none;
  color: gray;
}
.arrow-bread-crumb{
  font-size: 1.2em;
  color: #9f9f9f;
  padding: 0 .9em;
  vertical-align: text-bottom;
}
.tag-filter{
  text-decoration: none;
  display: inline-block;
  padding: 0.5em 1em;
  border: 1px solid gainsboro;
  border-radius: 8px;
  cursor: pointer;
}
.tag-filter:hover{
  background: #b0d3f5;
}
.tag-filter:active{
  background: white;
}
.tag-phone{
  text-decoration: none;
  display: inline-block;
  padding: 0.3em 1em;
  border: 1px solid gainsboro;
  border-radius: 18px;
  cursor: pointer;
}
.tag-phone:hover{
  background: #b0d3f5;
}
.tag-phone:active{
  background: white;
}
.tag-reg-ok{
  background: #f3c3c7 !important;
  color: #911212 !important;
}
.tag-reg{
  text-decoration: none;
  display: inline-block;
  padding: 0em .4em;
  border: 1px solid gainsboro;
  border-radius: 18px;
  cursor: pointer;
  color: #9f9e9e;
  background: white;
}
.tag-reg:hover{
  background: #b0d3f5;
}
.tag-reg:active{
  background: white;
  color: red;
}
.sel-typ{
  padding: 0.8em 1em;
}
.mr-m{
  margin-right: .3em;
}
.mr-1{
  margin-right: .5em;
}
.mr-2{
  margin-right: 1em;
}
.mr-3{
  margin-right: 1.5em;
}
.bg-white{
  background: white;
}
.tt-disq{
  font-weight: 500;
  font-size: .85em;
}
.itm_icn{
  text-decoration: none;
  display: block;
  font-size: .85em;
}
.mr-6{
  margin-right: 3em;
}
.phone-number{
  color: black;
}
.phone-number:hover{
  color: blueviolet;
}
.cl-st-gray{
  color: #a3a3a3 !important;
}
.cl-st-gray:hover{
  color: #7b7b7b;
}
.cl-st-gray:active{
  color: #a3a3a3;
}
.cl-st-green{
  color: #0bb96a !important;
}
.cl-st-orange{
  color: #ff903f !important;
}
.cl-st-red{
  color: #dc3545 !important;
}
.icon-phone-tg{
  width: 21px;
  height: 21px;
  vertical-align: sub;
  color: #3f51b5;
}
.icon-whats-tg{
  width: 21px;
  height: 21px;
  vertical-align: sub;
  color: #0b8b50;
}
.icon-phone-md{
  width: 14px;
  height: 14px;
  vertical-align: sub;
  color: #3f51b5;
}
.img-profile{
  color: transparent;
  width: 120px;
  height: 120px;
  object-fit: cover;
  text-align: center;
  border-radius: 20px;
}
.img-profile-med{
  color: transparent;
  width: 35px;
  height: 35px;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
  border-radius: 50%;
}
.img-profile-sm{
  color: transparent;
  width: 25px;
  height: 25px;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
  border-radius: 50%;
}
.transparent{
  opacity: 0;
}
.d-inline{
  display: inline-block;
}
.img_smll_badge{
  display: inline-block;
  width: 25px;
  vertical-align: initial;
}
.content-pricing{
  position: relative;
  display: block;
  width: 100%;
  min-height: 100px;
}
.tt-large-prc{
  font-size: 1.8em;
  font-weight: bold;
  display: block;
}
.text-subtit{
  color: black;
  text-transform: uppercase;
  font-size: .8em;
}
.center-amount{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.borderpay{
  position: relative;
  padding: .5em;
  border: 1px solid #cecece;
  text-align: center;
}
.tt_brder {
  position: absolute;
  left: -0.75em;
  top: -0.7em;
  padding: 0.3em 0.9em;
  background: #f9f9f9;
  font-size: .85em;
  font-weight: bold;
  text-transform: uppercase;
}
.bnd_img {
  position: absolute;
  width: 32px;
  left: 2em;
  top: 2em;
}
.text_cb {
  display: block;
  font-size: 2.5em;
  margin: 0;
}
.text_nm_ltr {
  font-size: .85em;
  color: #060606;
  text-transform: uppercase;
}
.cont_inp_rec{
  position: relative;
  display: block;
  border: 1px solid #adadad;
  background: #f1f1f1;
}
.lbflt_rgt {
  position: absolute;
  left: 0.5em;
  top: 0.8em;
  font-weight: bold;
}
.inp_cb {
  display: inline-block;
  padding: 0.5em;
  width: 65%;
  font-size: 1.3em;
  padding-left: 5em;
  border: 0;
}
.cursor-pointer{
  cursor: pointer;
}
.inp_pay {
  display: inline-block;
  padding: 1em 0.5em;
  width: 100%;
  font-size: 2em;
  border: 0;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #e6e3e3;
}
.inp_pay:focus {
  background-color: #fff;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(140 218 255 / 25%);
}
.inp_cmb {
  display: inline-block;
  border: 0;
  background: transparent;
  color: #09ab51;
  font-size: 2em;
  padding: 0.2em;
  width: 165px;
}
.inp_infl {
  position: absolute;
  right: 0.1em;
  top: 0.1em;
  padding: 0.65em;
  width: 120px;
  background: #f1f1f1;
  border: 0;
}
.pt-cus-2{
  padding: 0.9em 1em;
}
.float-icon{
  position: absolute;
  font-size: 1.2em;
  top: 0.55em;
  left: 0.7em;
  color: #1976d2;
}
.p-4-icon{
  padding-left: 3em;
}
.invc-cont{
  color: #686464  !important;
}
.cont-client-des{
  position: relative;
  padding: .3em;
  background: #e7e7e7;
  border-radius: 8px;
  border: 1px solid #f9f9f9;
}
.float_img_star{
  position: absolute;
  width: 23px;
  left: 0.5em;
  top: 0.5em;
  z-index: 150;
}
.float_icn_star{
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0.5em;
  top: 0.7em;
  z-index: 150;
  color: #c1c1c1;
}
.cont-ip {
  position: relative;
}
.pdf-inp{
  padding-left: 3.5em !important;
}
.active-m{
  background: #dbefff;
  color: #1976d2;
  font-weight: bold;
}
.sle-tran{
  border: 0;
  color: #1976d2;
  font-weight: bold;
  border-radius: 5px 0 0 5px;
}
.item-month{
  cursor: pointer;
}
.item-month:hover{
  color: #1976d2;
  font-weight: bold;
}
.btn-circle{
  border: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  color: gray;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}
.btn-circle:hover{
  background: #e5dede;
  color: black;
  cursor: pointer;
}
.tracker_mp3_full{
  border-radius: 20px !important;
  transform: .5s !important;
}
.tracker_mp3_full_v{
  border-radius: 20px !important;
  transform: .5s !important;
}
.reproductor_float{
  display: flex !important;
  border-radius: 50% !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  height: 0 !important;
  text-align: center;
  line-height: 0px !important;
  transform: .5s !important;
  margin-bottom: 1.8em;
}
.reproductor_float .rap-pp-button{
  /* display: none !important; */
  /* visibility: hidden; */
  /* position: absolute;
  left: 49.5%;
  top: 2.6em; */
}
.reproductor_mp3{
  display: block !important;
  border-radius: 50% !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 45px !important;
  height: 45px !important;
  text-align: center;
  line-height: 59px !important;
  transform: .5s !important;
}
.reproductor_mp3 .rap-controls{
  display: none !important;
}
.reproductor_mp3 .rap-volume{
  display: none !important;
}
.rap-container svg{
  width: 15px !important;
  padding-left: .1em;
}
.rap-container .rap-pp-button{
  display: inline-block !important;
}
.reproductor_mp3:hover{
  background: #b0d3f5;
}
.rap-container .rap-spinner{
  display: inline-block !important;
  line-height: 18px !important;
}
.max-w-1200{
  max-width: 1200px;
}
.showMovil{
  display: none;
}
.szh-accordion {
  margin-top: 1rem;
}
.szh-accordion__item {
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: .2rem;
  font-size: 1rem;
  text-align: left;
  background-color: transparent;
  border: none;
}

.szh-accordion__item:hover {
  background-color: #f3f3f3;
}
.szh-accordion__item-heading{
  width: 100%;
  text-align: left;
}
.szh-accordion__item-btn {
  position: relative;
  width: 100% !important;
  display: block;
  font-size: .85em;
  padding: .8em 1em;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 2px 3px gainsboro;
  text-align: left;
  border: 0;
  cursor: pointer;
}
.szh-accordion__item-btn:hover{
  color:#416af9;
}
.szh-accordion__item--expanded .szh-accordion__item-btn{
  background-color: #e7e7e7;
  color:#416af9;
}
.szh-accordion__item-content{
  width: 100%;
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}
.szh-accordion__item-panel{
  padding: 1rem;
}
.icn_chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  color:#416af9;
}

.szh-accordion__item--expanded .icn_chevron {
  transform: rotate(180deg);
}
.cl-mg-top{
  margin-top: 0 !important;
}
.rhap_controls-section{
  flex: 0 0 auto !important;
}
.rhap_container{
  background: transparent !important;
}
.rhap_volume-controls {
  display: none !important; /* Oculta el control de volumen */
}
.rhap_current-time{
  color: #707070 !important;
  font-size: .9em !important;
}
.rhap_total-time{
  color: #707070 !important;
  font-size: .9em !important;
}
.custom-player-1 .rhap_container svg {
  color: #141414 !important;
}
.rhap_container svg{
  color: #f3f3f3 !important;
}
.rhap_progress-indicator{
  background: #007bff !important;
}
.rhap_progress-filled{
  background-color: #2196f3 !important;
}
.custom-button{
  position: relative;
  background: transparent;
  border: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 25px !important;
  margin-top: .35em;
}
.custom-button:hover{
  background: #2e3236;
}
.ln-41{
  line-height: 41px;
}
.rhap_time{
  width: 45px !important;
}
.ret-back-10{
  font-size: 1.3em;
  transform: scaleX(-1);
  color: gray;
}
.ret-next-10{
  font-size: 1.3em;
  color: gray;
}
.flt-ret-l{
  position: absolute;
  font-size: .45em;
  color: gray;
  right: 1.4em;
  top: -.6em;
}
.flt-ret-r{
  position: absolute;
  font-size: .45em;
  color: gray;
  left: 1.5em;
  top: -.6em;
}
/* Estilo del contenedor */
.custom-checkbox {
  display: flex;
  position: relative;
  border: 1px solid gainsboro;
  color: #434242;
  padding: .8em 1em;
  border-radius: 20px;
  transition: color 0.3s ease;
}
.custom-checkbox:hover{
  border: 1px solid #2196f3;
}
.custom-is_check{
  border: 1px solid #2196f3;
  color: #007bff;
}
.spn_chk{
  font-size: .85em;
  text-transform: uppercase;
  margin: 0 .5em;
}
.isOnline{
  position: absolute;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  top: 1.7em;
  right: 1em;
}
.Ogreen{
  background:rgb(9, 173, 64);
}
.Ored{
  background:rgba(235, 19, 19, 0.829);
}
.form-circ {
  border-radius: 50%;
}
.no-inline{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6.8em;
  display: block;
  background-color: rgb(255, 227, 227);
  color: red;
  text-align: center;
  font-size: .75em;
  padding: .1em 0;
}
.cont-not-conection{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 180px;
  height: 280px;
  margin: auto;
}
.icn_wifi_not{
  display: inline-block;
  font-size: 5em;
  color: rgb(172, 172, 172);
  width: 100%;
}
.text_ul_sm{
  font-size: .85em;
  color: gray;
}
.tt_avis_not{
  padding: .5em 0;
  font-size: 1.1em;
}
.rw-cm-t{
  position: relative;
  border-bottom: 1px solid gainsboro;
  padding: .5em 0;
  margin: .2em 0;
  font-size: .8em;
}
.button-social-login{
  display: block;
  border: 0;
  position: relative;
  border-radius: 20px;
  padding: .6em 1.5em;
  background-color: #ffffff;
  border:1px solid gainsboro;
}
.lg-icn-face{
  font-size: 1.5em;
  color: #1261db;
}
.lg-icn-goog{
  font-size: 1.5em;
  color: #939393;
}
.lg-icn-open{
  font-size: 1em;
  color: #b8b8b8;
}
.footer-login{
  position: fixed;
  bottom: 2em;
  left: 0;
  right: 0;
  font-size: .85em;
}
.opc-3{
 opacity: .3;
}
.icon_done_large{
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  background: #016cdc;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
  padding: 1em;
}
.tag-search{
  position: relative;
  background: #efefef;
  border-radius: 5px;
}
.isBlur{
  filter: blur(10px);
  pointer-events: none;
}
.btn-closed{
  width: 35px;
  height: 35px;
  text-align: center;
  border: 0;
  background: gainsboro;
  border-radius: 50%;
}
.contain_id_side{
  position: relative;
  padding: 0.7rem 2rem;
}
.icon-cp-s{
  color: #838383;
}
.icon-cp-s:hover{
  color:#004ab9;
}
.icon-cp-s:active{
  color:#000000;
}




/* MEDIA QUERYS */
@media only screen and (min-width: 1400px) {
  .content-central{
    max-width: 1300px;
    height: 700px;
  }
  .cont_login{
    margin-top: 6em;
  }
  .cont_signup{
    margin-top: 4em;
  }
}
@media only screen and (max-width: 967px) {
  .fixed-right-menu{
    display: none;
    right: 0;
    width: 100%;
    z-index: 5;
  }
  .ms-sm-auto{
    margin-left: 0 !important;
  }
  .ms-sm-rauto{
    margin-right: 0 !important;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .cont_logo{
    width: auto;
    border-right: 0;
  }
  .user_opc{
    right: 0em;
    border-left: 0;
  }
  .icon-menu{
    visibility: visible;
  }
  .show{
    display: inline !important;
  }
  .hidden{
    visibility: none !important;
  }
  .md-fs-11{
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 750px) {
  .md-justify-center{
    align-items: center;
  }
  .sl-trans{
    font-size: .85em;
  }
  .img-profile-sm{
    width: 21px;
    height: 21px;
  }
  .showMovil{
    display: block;
  }
  .content-central{
    height: auto;
    overflow-y: scroll;
  }
  .hg-md-200{
    height: 200px;
  }
  .cont_img_l{
    max-width: 70%;
    margin: 0 auto;
    margin-top: -7em;
  }
  .show-pass{
    top: .85em;
  }
  .btn_login{
    height: 48px;
    border-radius: 20px;
  }
  .inp_login{
    height: 3.3rem;
  }
  .icon-inp{
    top: .7em;
  }
  .img-inp{
    top: .7em;
  }
  .content-central{
    border-radius: 0;
  }
  .ocultar-med{
    display: none;
  }
  .md-p-2{
    padding: 1em;
  }
  .cont_login{
    margin-top: 13em;
  }
  .cont_signup{
    margin-top: 1em;
  }
  .sm-txt-18{
    font-size: 2em !important;
  }
}
@media only screen and (max-width: 480px) {
  .fixed-left-bottom{
    left: 2em;
    right: 2em;
    border-radius: 40px !important;
    bottom: 3em;
    border-radius: 0;
    border: 0;
  }
  .nav-link-m{
    margin-top: .3em;
  }
  .mr-tp-icn{
    top: .8em !important;
  }
  .icon_friends{
    font-size: 1.8em;
  }
  .spinner_download{
    margin-left: 0 !important;
  }
  .sz-shar-icn{
    vertical-align: bottom;
    margin-right: .3em;
  }
  .sm_is_link{
    color: #007bff;
    font-size: 1em !important;
  }
  .btn-closed{
    position: absolute !important;
    top: -2em;
    right: 0;
    box-shadow: 0px 3px 5px #bbb5b5;
  }
  .img_tit_log{
    width: 85px;
  }
  .icon-message{
    width: 1.6em;
    height: 1.6em;
    padding: .5em !important;
  }
  .btn_donload_sm {
    display: block;
    margin-left: 0 !important;
    padding: 0;
    width: 100%;
  }
  .form-circ{
    width: 24px;
    height: 24px;
  }
  .custom-checkbox{
    padding: .8em .5em;
  }
  .sm-w-100{
    width: 100% !important;
  }
  .w-120{
    width: 80px;
  }
  .btn_play{
    width: 40px;
    height: 40px;
    line-height: 42px !important;
    padding-left: .6em !important;
  }
  .icon_move{
    font-size: 1.5em;
    margin-top: .3em;
  }
  .main_option_center{
    top: 0em;
  }
  .fixed-upload{
    position: fixed;
  }
  .btn-mv-hg{
    line-height: 2.9 !important;
  }
  .nombre_user {
    display: none;
  }
  .center-content{
    width: 80%;
  }
  .isDownloaded{
    position: absolute;
    top: 1.3em;
    right: 1.5em;
  }
  .tag_tt_song{
    font-size: 1.2em;
  }
  .cont_downloaad{
    padding: 0.8 1em;
  }
  .cont_logo{
    padding: .3em 0 !important;
  }
  .icn_fl_sel{
    top: .9em;
  }
  .inpd_m{
    padding: .9em 1em !important;
    height: 55px;
    line-height: 55px !important;
    width: 100% !important;
  }
  .sm-img-med{
    width: 35px;
    height: 35px;
  }
  .inl_bk_sm {
    display: inline-block !important;
    margin-top: .7em !important;
  }
  .clear_sl_p {
    padding: .9em 1em !important;
    -webkit-appearance: menulist-button !important;
    line-height: 45px !important;
    height: 50px !important;
  }
  .btn_closed_track{
    position: absolute !important;
    right: .2em;
    top: -2.2em;
    background: #4d4d4d;
  }
  .btn_track:hover {
    background:#212529;
  }
  .btn_track:active {
    color:#2e3236;
  }
  .m-w-600{
    width: 100%;
  }
  .max-w-600{
    width: 90%;
  }
  .sm-col-8{
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .sm-col-6{

  }
  .sm-col{
    flex: 1 0 0%;
  }
  .w-rep {
    width: 100%;
  }
  .isOnline{
    top:2em;
    right: -.6em;
  }
  .fixed-play-track{
    left: .2em;
    bottom: 6em;
    right: .2em;
    z-index: 6;
  }
  .stx_tt {
    margin-top: 0em !important;
  }
  .icn-mov{
    width: 100%;
    font-size: 2em;
    display: block;
  }
  .fixed-bottom-menu{
    display: block;
  }
  .sm-text-left{
    text-align: left !important;
  }
  .sm-text-center{
    text-align: center !important;
  }
  .cerrar_modal{
    top: 1.4em;
  }
  .btn_back{
    display: block !important;
  }
  .item_opc_salir{
    padding: .5em .8em;
  }
  .menu_salir{
    width: 250px;
  }
  .link_text{
    font-size: 1em;
  }
  .modal-tittle{
    margin-left: 1em;
  }
  .is_link, .is_link_purple{
    text-transform: uppercase;
  }
  .link-tab {
    position: relative;
    text-align: center;
    padding: 1em 1em;
    font-size: .85em;
    border: 1px solid gainsboro;
    box-shadow: none;
    border-radius: 24px !important;
  }
  .act-tab {
    box-shadow: 0px 2px 3px #caddfb;
    background: #e4efff;
    color: #104a9f;
  }
  .txt_tab, .sm-txt-85{
    font-size: .8em;
  }
  .fixed-right-menu .nav-link{
    border-bottom: 1px solid #d1d1d1;
    padding: 0.8rem 1.5em;
  }
  .table_outline td{
    padding: 15px 4px;
  }
  .btn-flt-mov{
    width: 100%;
    padding: .8em .1em;
    line-height: 4px;
    margin-left: .5em;
  }
  .modal_body{
    padding: 2em 1.5em;
  }
  .head_modal{
    position: fixed;
    padding: .8em 2em;
    width: 100%;
    top: 0;
    z-index: 1000;
    border-radius: 0;
  }
  .inp_add{
    padding: 0.78em 1em;
  }
  .modal_footer{
    position: fixed !important;
    padding-bottom: 2em;
  }
  .user_opc_cont{
    padding: 0;
  }
  .cont-hd-right{
    margin-top: .8em;
  }
  .sl-trans{
    width: 50px;
  }
  .user_opc{
    padding: 0.65em .5em;
  }
  .sm-py-1{
    padding: .5em 0;
  }
  .cl-mg-0{
    margin: 0 !important;
  }
  .sm-p-1{
    padding: .2em;
  }
  .sm-py-2{
    padding: 1em 0;
  }
  .sm-py-3{
    margin: 1.5em 0;
  }
  .tag-phone-mv{
    padding: .8em 1em !important;
    font-size: .85em;
  }
  .sm-my-3{
    margin: 1.5em 0;
  }
  .sm-my-3{
    margin: 1.5em 0;
  }
  .szh-accordion__item-panel{
    padding: .5em .3em;
  }
  .ocultar-sm{
    display: none !important;
  }
  .title-crd-t{
    font-size: .9em;
  }
  .md-p-2{
    padding: 0 !important;
  }
  .sm-px-0{
    padding: 0 0 !important;
  }
  .sm-pl-2{
    padding-left: 1em;
  }
  .tag_inl{
    display: inline;
    font-size: .85em;
  }
  .sm-mov-85{
    font-size: .8em;
    color: black;
  }
  .sm-wrapper-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
  }
  .main_modal{
    margin-top: 0;
    overflow-y: scroll;
    height: calc(100%);
    border-radius: 0;
  }
  .hg-auto{
    padding-bottom: 0 !important;
  }
  .flex-column-sm{
    flex-direction: column !important;
  }
  .barr_gray{
    height: 5px;
  }
  .line_progress{
    height: 3px;
  }
  .show-sm{
    display: inline-block !important;
  }
  .plus_btn{
    margin-top: -.2em;
  }
  .cnt_inp{
    max-width: 85%;
  }
  .custom-player-2 .rhap_progress-section, .rhap_controls-section{
    flex: 0 0 auto !important;
    width: 100% !important;
    justify-content: start !important;
    margin: .2em 0;
  }
  .custom-player-2 .rhap_horizontal-reverse{
    flex-direction: column-reverse !important;
  }
  .custom-player-1 .rhap_progress-section{
    flex: 0 0 auto !important;
    width: 85% !important;
    justify-content: start !important;
    margin: .2em 0;
  }
  .custom-player-1 .rhap_controls-section{
    flex: 0 0 auto !important;
    width: 15% !important;
    justify-content: start !important;
    margin: .2em 0;
  }
  .btn_track{
    width: 35px;
    height: 35px;
  }
  .custom-button{
    margin-top: .15em;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .min-w-btn{
    min-width: 90px;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }
  .col-sm-2{
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-3{
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .col-sm-9{
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10{
    flex: 0 0 auto;
    width: 83.3333333333%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .icon_Sa{
    width: 15px;
    margin-right: 0;
    margin-top: .5em;
  }
  .list_p{
    margin-top: 0;
  }
}
@media only screen and (max-width: 320px) {

}



.container-loading{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  width: 120px;
  height: 120px;
  line-height: 120px !important;
  text-align: center;
}
.container-full{
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #f1f1f1;
  border-radius: 10px;
  animation: load_row 1.2s infinite;
}
.row_small{
  background: #f1f1f1;
  border-radius: 10px;
  padding: 1.5em 1.5em;
  animation: load_row 1.2s infinite;
}
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
.sippiner{
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #007bff;
  border-right: 3px solid #007bff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  animation: spin .5s linear infinite;
}
.spinner_sm{
  display: inline-block;
  border: 4px solid #d1d1d1;
  border-top: 4px solid #007bff;
  border-right: 4px solid #007bff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin .4s linear infinite;
}
.spinner_download{
  display: inline-block;
  border: 3px solid #efefef;
  border-top: 3px solid #09ad40;
  border-right: 3px solid #1cc153;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin-left: .5em;
  vertical-align: inherit;
  animation: spin .3s linear infinite;
}
.zoomIn {
  -webkit-animation:zoomIn .3s 1;
  animation:zoomIn .3s 1;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes placeHolderShimmer {
  0% {
      background-position: -468px 0
  }
  100% {
      background-position: 468px 0
  }
}
@keyframes load_row {
  0%{
      opacity: 1;
  }
  40%{
      opacity: .4;
  }
  80%{
      opacity: .8;
  }
  100%{
      opacity: 1;
  }
}
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}















.pulse {
  -webkit-animation:grow 1s infinite;
  animation:grow 1s infinite;
}
@keyframes grow {
  0% {
    transform: scale(.9);
    opacity: 1;
  }
  60% {
    transform: scale(1.1);
    opacity: .8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.bounceInUp {
  -webkit-animation: bounceInUp .6s 1;
  animation: bounceInUp .6s 1;
}
.showLoad {
  -webkit-animation: showLoad 1s 1;
  animation: showLoad 1s 1;
}
.slideInUp {
  -webkit-animation:slideInUp .5s 1;
  animation:slideInUp .5s 1;
}
.rubberBand {
  -webkit-animation:rubberBand .5s 1;
  animation:rubberBand .5s 1;
}
.slideOutDown-edit {
  -webkit-animation: slideOutDownmd .5s 1;
  animation: slideOutDownmd .5s 1;
}
.bounceInLeft {
  -webkit-animation:.5s bounceInLeft 1;
  animation:.5s bounceInLeft 1;
}
.fadeIn {
  -webkit-animation: fadeIn .5s 1;
  animation: fadeIn .5s 1;
}
.fadeInDown {
  -webkit-animation: fadeInDown .5s 1;
  animation: fadeInDown .5s 1;
}
.fadeOut {
  -webkit-animation: fadeOut .5s 1;
  animation: fadeOut .5s 1;
}
.Isexpand {
  -webkit-animation: expand .5s 1;
  animation: expand .5s 1;
}
/* Animaciones */
/* Fading entrances  */
@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/* Sliding entrances */
@-webkit-keyframes slideOutDownmd {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    opacity: .4;
  }
}
@keyframes slideOutDownmd {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
}
@-webkit-keyframes slideInUpOpc {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
@keyframes slideInUpOpc {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes showLoad {
  from {
    opacity: 0;
    border-radius: 60% 60% 0 0;
  }

  50% {
    opacity: 1;
    border-radius: 0;
  }
}
@-webkit-keyframes showLoad {
  from {
    opacity: 0;
    border-radius: 60% 60% 0 0;
  }

  50% {
    opacity: 1;
    border-radius: 0;
  }
}
@keyframes carr_prelod {
  0% {
    left: -20em;
  }
  70%{
    left: 80%;
  }
  100%{
    left: 100%;
    width: 100%;
  }
}
@keyframes expand {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0) scaleY(5);
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0) scaleY(0.9);
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0) scaleY(0.95);
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0) scaleY(0.985);
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}








/************ Vista de impresion ***********/
@media print {
  * {
      -webkit-print-color-adjust: exact;
    }
    body{
      background: white !important;
    }
    .cont_mensaje, .fixed-right-menu, .header, .container_ventas, .hd_print_tick, .no_print, .mensaje, .cerrar_modal{
      display: none !important;
    }
    .table_ser td, .table_ser th{
      border-bottom: 1px dotted blue;
    }
    .print-m-0{
      margin: 0 !important;
    }
    .separador_pr{
      display: block;
      height: 10px;
      width: 100%;
    }
    .table_ser{
      width: 100% !important;
    }
    .w-90{
      width: 100% !important;
    }
    .wd_95, .col-lg-10, .col-md-12, .w-70{
      width: 100% !important;
    }
    .clear-pd-mg{
      padding: 0 !important;
      margin: 0 !important;
    }
    .container_main{
      margin-top:0 !important;
    }
    .con_head_fac{
      max-width: 87% !important;
      margin: 0 auto;
    }
    .main_modal{
      margin-top: .7em;
      max-width: 100%;
      margin-bottom: 0;
      box-shadow: none !important;
      background: transparent !important;
      border: 0 !important;
    }
    .cont_modal{
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
      background: transparent !important;
      overflow: auto !important;
      box-shadow: none !important;
      width: 100%;
      border: 0;
    }
    .modal_opa{
      background: transparent;
    }
}